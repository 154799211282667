<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <h1>非凸活动</h1>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-24.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1tFt2eqEA6/?spm_id_from=333.999.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：第四届中国Rust开发者大会</div>
                  <div>活动时间：2024.09.07-08</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：Rust 中文社区</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1tFt2eqEA6/?spm_id_from=333.999.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://www.bilibili.com/video/BV1Ab4zerEi4/?spm_id_from=333.999.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-23.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="/live-stream-poster-23.jpg"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：Rust语言在量化交易中的应用实践</div>
                  <div>活动时间：2024.06.27 15:00-17:30</div>
                  <div>分享人：波粒二象、艾悉资产、非凸科技</div>
                  <div class="mb-4">主办方：东北证券</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://meeting.tencent.com/crm/lRek4kJN67"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-23.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-22.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1zH4y1p7yb/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：Rust在金融领域的应用</div>
                  <div>活动时间：2024.03.24 20:00-21:00</div>
                  <div>分享人：赵海峰</div>
                  <div class="mb-4">主办方：Rust.cc</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1zH4y1p7yb/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-22.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-21.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1ix4y1U7tD/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：量化&校招 直播宣讲</div>
                  <div>活动时间：2024.03.21 20:30-21:00</div>
                  <div>分享人：Mavis，Amy</div>
                  <div class="mb-4">主办方：复旦大学量化投资协会</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1ix4y1U7tD/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-21.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-20.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1KQ4y187Sq/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：基于Rust的安全、有收益的通用交易算法</div>
                  <div>活动时间：2023.11.08 20:30-21:00</div>
                  <div>分享人：陆一洲</div>
                  <div class="mb-4">主办方：Rust.cc，TinTin</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1KQ4y187Sq/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-20.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-19.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1ej411Y7et/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：用量化的方法拿更好的offer</div>
                  <div>活动时间：2023.11.05 19:00-20:00</div>
                  <div>分享人：陆一洲</div>
                  <div class="mb-4">主办方：上海交通大学量化协会</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1ej411Y7et/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-19.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-18.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1M94y157mH/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：基于level2数据的限价单成交概率预测及其在算法交易上的实践应用</div>
                  <div>活动时间：2023.10.29 19:00-20:00</div>
                  <div>分享人：王志辉</div>
                  <div class="mb-4">主办方：复旦大学量化投资协会</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1M94y157mH/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-18.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-17.jpg"
                    alt="人大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1ow411B7Hn/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：算法交易基础和工程实践</div>
                  <div>活动时间：2023.10.22 20:00-21:00</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：中国人民大学量化协会</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1ow411B7Hn/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-17.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-16.jpg"
                    alt="开源中国"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1234y1M76U/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：用量化的方法拿更好的offer</div>
                  <div>活动时间：2023.10.19 19:00-20:00</div>
                  <div>分享人：陆一洲</div>
                  <div class="mb-4">主办方：香港中文大学（深圳）量化交易与投资协会</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1234y1M76U/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-16.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-15.jpg"
                    alt="开源中国"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1294y1a7UP/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：数据与前沿技术</div>
                  <div>活动时间：2023.09.23 13:30-17:30</div>
                  <div>分享人：赵海峰</div>
                  <div class="mb-4">主办方：开源中国 OSC源创会</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1294y1a7UP/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-15.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-14.jpg"
                    alt="运筹offer"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1Hu4y1r7ah/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：职场会客厅——对话非凸科技首席架构师</div>
                  <div>活动时间：2023.09.17 19:00-20:00</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：运筹offer</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1Hu4y1r7ah/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-14.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-13.jpg"
                    alt="机器之心"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1tV411P79K/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：AI offer 最优解——2024届秋招线上宣讲会</div>
                  <div>活动时间：2023.09.12 19:00-21:00</div>
                  <div>分享人：艾洁</div>
                  <div class="mb-4">主办方：机器之心 Talent AI</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1tV411P79K/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-13.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-12.jpg"
                    alt="中科大"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV11x4y1X7XZ/?spm_id_from=444.41.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：打开量化私募的黑箱</div>
                  <div>活动时间：2023.07.30 16:00-18:00</div>
                  <div>分享人：陆一洲</div>
                  <div class="mb-4">主办方：中国科学技术大学管理学院学生会</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV11x4y1X7XZ/?spm_id_from=444.41.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://mp.weixin.qq.com/s?__biz=MzA3Mzg5Njc2OA==&mid=2247487515&idx=1&sn=4a461b617ccd54447aa7b2c6c76c596b&chksm=9f095634a87edf226f058edea7968d441925650a6e76b4542f273c8bcb33d82f69c7a2009c88&token=1275373855&lang=zh_CN#rd"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-11.jpg"
                    alt="WAIC开源集市"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1WN411m7tq/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：2023世界人工智能大会·开源集市</div>
                  <div>活动时间：2023.07.06-08</div>
                  <div>分享人：陆一洲</div>
                  <div class="mb-4">主办方：国家发展和改革委员会等</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1vm4y1E7xv/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://mp.weixin.qq.com/s/Byi7VITdrdTRBAoP-nkeTA"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-10.jpg"
                    alt="Rust开发者大会"
                    class="w-100"
                  />
                  <a
                    href="https://mp.weixin.qq.com/s/GiV-9MrlzB-yHMW6boThwg"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：第三届中国Rust开发者大会</div>
                  <div>活动时间：2023.06.17-18</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：Rust中文社区</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV16o4y1P7RY/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355ag"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://www.bilibili.com/video/BV1cm4y1Y7uA/?spm_id_from=444.41.list.card_archive.click&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-09.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://mp.weixin.qq.com/s/p_kP-z1K-blsnKT-blMitg"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：第五届全球金融科技创业大赛</div>
                  <div>活动时间：2023.03.23 13:30-18:00</div>
                  <div>分享人：王浚澎</div>
                  <div class="mb-4">指导单位：清华五道口</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://mp.weixin.qq.com/s/p_kP-z1K-blsnKT-blMitg"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://www.weiyangx.com/2022fintech"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-07.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://h5.xunzhuang.net/h5/U140105D6YY35AY93"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：e海通聚研讨会</div>
                  <div>活动时间：2023.01.12 15:00-17:00</div>
                  <div>分享人：王浚澎</div>
                  <div class="mb-4">主办方：海通证券&非凸科技</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/read/cv21216995?spm_id_from=333.999.0.0"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="https://h5.xunzhuang.net/h5/U140105D6YY35AY93"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
       <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-06.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV19W4y147KE/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>分享主题：Rust在算法交易中的实际应用与积极效应</div>
                  <div>分享时间：2022.11.06 9:00-17:00</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：中泰证券 XTP</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV19W4y147KE/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >直播回放</a
                  >
                  <a
                    href="/live-stream-poster-06.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-05.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV18e4y1h7GJ/?vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：量化投资中的机器学习</div>
                  <div>活动时间：2022.09.08 18:00-20:00</div>
                  <div>分享人：李佐凡</div>
                  <div class="mb-4">直播支持：复旦大学管理学院</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV18e4y1h7GJ/?vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-05.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-04.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1sW4y117h1?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：Rust 助力量化高频交易</div>
                  <div>活动时间：2022.07.31 14:10-14:40</div>
                  <div>分享人：陆一洲</div>
                  <div class="mb-4">直播支持：Rust China Conf</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1sW4y117h1?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-04.png"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-03.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1Sm4y1B7QJ/?vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：量化策略&算法研究专场</div>
                  <div>活动时间：2022.04.14 19:00-20:30</div>
                  <div>宣讲人：策略研究专家</div>
                  <div class="mb-4">校方站：复旦大学</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1Sm4y1B7QJ/?vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-03.png"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-02.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://meetings.feishu.cn/s/1hpmfge0tj5kz?src_type=3"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：Rust 如何为量化行业加速赋能？</div>
                  <div>活动时间：2022.03.06 15:00-16:00</div>
                  <div>宣讲人：朱为</div>
                  <div class="mb-4">直播支持：Rust 中文社区</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://meetings.feishu.cn/s/1hpmfge0tj5kz?src_type=3"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-02.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row gy-5">
           <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                   <img
                    src="../../assets/img/join/live-stream-banner-01.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="http://live.csdn.net/room/csdnstudent/66vOk2ke"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-5 px-5 fs-5 lh-lg livestream">
                  <div>活动主题：非凸科技-带你走进量化交易</div>
                  <div>活动时间：2022.02.11 19:00-20:00</div>
                  <div>宣讲人：朱为</div>
                  <div class="mb-4">直播支持：CSDN直播间</div>
                  <a
                    role="button"
                    target="_blank"
                    href="http://live.csdn.net/room/csdnstudent/66vOk2ke"
                    class="btn btn-primary me-3"
                    >活动回顾</a
                  >
                  <a
                    href="/live-stream-poster-01.png"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
              </div>
      </section>

    </div>
  </div>
</template>

<script>
export default {
  name: "LivestreamPoster",
};
</script>

<style scoped lang="scss">
.container-lg {
  border-radius: 2rem;
}
.icon {
  font-size: 4rem;
}
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/livestream-banner.jpg");
  background-position-x: 25%;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.poster {
  width: 100%;
}
.video-aside-text {
  background-color: aliceblue;
  letter-spacing: 0.5px;
}

@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 2.5rem !important;
    text-align: center;
  }
  .jumbotron-wrapper.image {
    background-position-x: 25%;
  }
  .video-aside-text {
    padding: 1.5rem !important;
  }
}
</style>
